@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Nunito Sans', sans-serif;
}

@font-face {
  font-family: Ageer;
  src: url('./fonts/LeagueSpartan-Bold.otf') format('opentype');
}

.play-center {
  left: calc(50vw - 158px);
}

.play-center {
  left: calc(50vw - 158px);
}

@media (min-width: 320px) {
  .play-center {
    left: calc(50vw - 60px);
  }
}

.block-container {
  width: 1140px;
  margin: auto;
}

.block-container-907 {
  width: 907px;
  margin: auto;
}

.connect-wallet {
  text-align: center;
  width: 190px;
  background: linear-gradient(270deg, #9de40b -12.59%, #ffd437 100%);
  border-radius: 20px;
}

.switch {
  display: inline-block;
  height: 26px;
  position: relative;
  width: 52px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #252934;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #000;
  bottom: 8px;
  content: '';
  height: 12px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 12px;
}

input:checked+.slider {
  background-color: #9de40b;
}

input:checked+.slider:before {
  transform: translateX(30px);
}

.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

/* SQUARED THREE */

input[type='checkbox'] {
  visibility: hidden;
}

input[type='text'] {
  background-color: #262e36;
}

.ant-modal-content .ant-input,
.ant-modal-content .ant-input-affix-wrapper {
  background-color: #13151D !important;
  border-width: 0;
  border-color: transparent !important;
  color: #ffffff;
}

.ant-modal-content {
  box-shadow: none;
}

.ant-input-number {
  border-color: transparent !important;
  color: #ffffff;
  border-width: 0;
  box-shadow: none;
  background-color: transparent !important;
}

.ant-modal-content>button,
[type='button']:hover {
  border-color: #9DE40B;
  background-color: transparent;
  color: #ffff;
}

.ant-modal-content>button,
[type='button'] {
  border-color: transparent;
}

.ant-input-password-icon {
  color: #424858;
}

.textsell input[type='text'] {
  background-color: white;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox label {
  background-color: #262e36;
  border: 2px solid #262e36;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.custom-checkbox label:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 4px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #9de40b;
  opacity: 0;
}

.custom-checkbox input[type='checkbox'] {
  visibility: hidden;
}

.custom-checkbox input[type='checkbox']:checked+label {
  background-color: #030b14;
  border-color: #9de40b;
}

.custom-checkbox input[type='checkbox']:checked+label:after {
  opacity: 1;
}

.text-stroke-black-2 {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.text-stroke-black {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.bg-item-frame:hover {
  background-image: url('./images/item-frame-active.png');
}

.bg-hero-frame:hover {
  background-image: url('./images/hero-frame-active.png');
}

.token-red {
  background: radial-gradient(50% 50% at 50% 50%, #e8235e 0%, #81092d 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
}

.token-pink {
  background: radial-gradient(50% 50% at 50% 50%, #e8238d 0%, #81095f 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
}

.token-purple {
  background: radial-gradient(50% 50% at 50% 50%, #7923e8 0%, #5a0981 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
}

.token-green {
  background: radial-gradient(50% 50% at 50% 50%, #23e8dc 0%, #038566 100%);
  border-radius: 10px 0px 0px 10px;
}

.token-green2 {
  background: radial-gradient(50% 50% at 50% 50%, #4be615 0%, #2e8c0d 100%);
  border-radius: 10px 0px 0px 10px;
}

.ant-btn-primary {
  background-color: #1890ff !important;
}

.ant-pagination-prev a {
  border: none !important;
  background: transparent !important;
}

.ant-pagination-next a {
  border: none !important;
  background: transparent !important;
}

.ant-pagination-item {
  color: #5c6477;
  font-size: 16px !important;
  font-weight: 900 !important;
  background-color: transparent;
  border-color: transparent;
}

.ant-pagination-item a {
  color: #5c6477;
}

.ant-pagination-item-active {
  font-weight: 900 !important;
  font-size: 16px !important;
  background-color: transparent;
  border-radius: 40px;
  border-width: 1;
  border-color: #9de40b !important;
}

.ant-pagination-item-active a {
  color: #ffffff;
}

.ant-pagination-item:hover {
  border-color: transparent;
}

.ant-pagination-item a:hover {
  color: #ffffff;
}

.custom-modal .ant-modal-content {
  background-color: transparent;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  margin-top: -5px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: #5c6477 !important;
  font-size: 18px;
}

.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #ffffff !important;
}

.ant-pagination-item-ellipsis {
  margin-top: -5px;
  color: #5c6477 !important;
}

.ant-pagination-item-link-icon svg {
  color: #ffffff;
  margin-top: -5px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  color: white;
  padding: 0;
  margin-left: 14px;
}
.ant-collapse-borderless > .ant-collapse-item, .ant-collapse > .ant-collapse-item {
  border-width: 0;
}

.ant-collapse-item-active {
  background-color: #0C1946;
}
.ant-collapse-icon-position-right > .ant-collapse-item-active > .ant-collapse-header {
  color: #FFB800 !important;
}

.button-scale:active {
  transform: scale(0.95)
}

.button-dropdown:focus + .dropdown {
  display: block !important;
}
/* .dropdown {
  display: none;
} */
